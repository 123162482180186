// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-about-page-tsx": () => import("./../../../src/templates/about-page.tsx" /* webpackChunkName: "component---src-templates-about-page-tsx" */),
  "component---src-templates-atm-page-tsx": () => import("./../../../src/templates/atm-page.tsx" /* webpackChunkName: "component---src-templates-atm-page-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-blog-section-tsx": () => import("./../../../src/templates/blog-section.tsx" /* webpackChunkName: "component---src-templates-blog-section-tsx" */),
  "component---src-templates-country-page-tsx": () => import("./../../../src/templates/country-page.tsx" /* webpackChunkName: "component---src-templates-country-page-tsx" */),
  "component---src-templates-learning-page-tsx": () => import("./../../../src/templates/learning-page.tsx" /* webpackChunkName: "component---src-templates-learning-page-tsx" */),
  "component---src-templates-mining-page-tsx": () => import("./../../../src/templates/mining-page.tsx" /* webpackChunkName: "component---src-templates-mining-page-tsx" */),
  "component---src-templates-node-map-page-tsx": () => import("./../../../src/templates/node-map-page.tsx" /* webpackChunkName: "component---src-templates-node-map-page-tsx" */),
  "component---src-templates-sitemap-page-tsx": () => import("./../../../src/templates/sitemap-page.tsx" /* webpackChunkName: "component---src-templates-sitemap-page-tsx" */),
  "component---src-templates-treasuries-page-tsx": () => import("./../../../src/templates/treasuries-page.tsx" /* webpackChunkName: "component---src-templates-treasuries-page-tsx" */)
}

